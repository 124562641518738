import React, { Component } from "react";
import { applyVueInReact, connectVuex, withVueRouter } from "vuereact-combined";
import axios from "@/axios";
import SeatLayout from "./SeatLayout";
import MovieDate from "./MovieDate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../../assets/css/movie/movieShowTime.css";
class MovieShowTime extends Component {
  constructor() {
    super();
    this.state = {
      movieDetail: {},
      movieDate: [],
      selectedDate: {},
      selectedShow: {},
      seatModal: false,
    };
  }
  componentDidMount() {
    this.fetchMovieShowTime();
  }

  fetchMovieShowTime = () => {
    //Using live api
    axios
      .post("api/v2/services/movies/showtime", {
        MovieId: this.props.$vueRoute.params.movieId,
        ProcessId: this.props.$vueRoute.params.processId,
      })
      .then((data) => {
        this.setState(
          {
            movieDetail:
              data.data.response[0].GetMovieShowTimeResponse[0]
                .GetMovieShowTimeResult[0],
            movieDate:
              data.data.response[0].GetMovieShowTimeResponse[0]
                .GetMovieShowTimeResult[0].Dates[0].MovieDate,
          },
          () => {
            // INTITAL SELECTED DATE.
            if (this.state.movieDate && this.state.movieDate.length > 0) {
              this.setState({ selectedDate: this.state.movieDate[0] });
            }
          }
        );
      });
  };

  handleDateClick = (date) => {
    this.setState({
      selectedDate: date,
      selectedShow: {},
    });
  };

  handleShowClick = (show) => {
    this.setState({
      selectedShow: show,
      seatModal: true,
    });
  };
  closeSeatModal = () => {
    this.setState({ seatModal: false, selectedShow: {} });
  };

  render() {
    return (
      <>
        <ToastContainer closeOnClick position="top-right" autoClose={3000} />
        <div className="container">
          {this.state.seatModal && (
            <SeatLayout
              seatModal={this.state.seatModal}
              closeSeatModal={this.closeSeatModal}
              selectedShow={this.state.selectedShow}
              movieDetail={this.state.movieDetail}
              vuexDispatch={this.props.vuexDispatch}
            />
          )}

          <div className="box-second-row p-4">
            {Object.keys(this.state.movieDetail).length > 0 ? (
              <>
                {
                  <div className="row movie-description-container pt-3 pb-3">
                    <div className="col-12 col-lg-6 trailer">
                      <iframe
                        width="100%"
                        height="100%"
                        src={this.state.movieDetail.TrailerVideo[0]}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={this.state.movieDetail.MovieName}
                      />
                    </div>

                    <div className="col-12 col-lg-6">
                      <div className="movie-description row mt-3">
                        <div className="col-12">
                          <h2>{this.state.movieDetail.MovieName}</h2>
                        </div>
                      </div>
                      <div className="movie-description row">
                        <div className="col-12">
                          <span className="title">
                            {this.state.movieDetail.Genre}
                          </span>
                        </div>
                      </div>
                      <div className="movie-description row mt-4">
                        <div className="col-12">
                          <p>{this.state.movieDetail.Synopsis}</p>
                        </div>
                      </div>

                      <div className="movie-description row mt-4">
                        <div className="col-12">
                          <div className="movie-description-container">
                            <div className="description-title">
                              <span className="title"> Director:</span>
                            </div>
                            <div className="description-content">
                              <p>{this.state.movieDetail.Director}</p>
                            </div>
                          </div>
                          <div className="movie-description-container">
                            <div className="description-title">
                              <span className="title"> Cast:</span>
                            </div>
                            <div className="description-title">
                              <p>{this.state.movieDetail.Casts}</p>
                            </div>
                          </div>
                          <div className="movie-description-container">
                            <div className="description-title">
                              <span className="title"> Duration:</span>
                            </div>
                            <div className="description-title">
                              <p>{this.state.movieDetail.Duration}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {/* SELECTED DATE SECTION */}
                {Object.keys(this.state.selectedDate).length > 0 && (
                  <MovieDate
                    movieDate={this.state.movieDate}
                    handleDateClick={this.handleDateClick}
                    selectedDate={this.state.selectedDate}
                    handleShowClick={this.handleShowClick}
                    selectedShow={this.state.selectedShow}
                  />
                )}
              </>
            ) : (
              <div className="card-loader"></div>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    vuexDispatch: (actionName) => {
      return dispatch(actionName);
    },
  };
};
export default connectVuex({
  mapDispatchToProps,
})(withVueRouter(MovieShowTime));
