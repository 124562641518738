import React, { Component } from "react";

class MovieDate extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <>
        <div className="row mt-5">
          <div className="col-12">
            <h4>Show Time</h4>
          </div>
          <div className="col-12">
            <div className="scrolling-wrapper row flex-row flex-nowrap mt-2">
              {this.props.movieDate?.length > 0 ? (
                this.props.movieDate.map((date) => {
                  return (
                    <div
                      className="col-6 col-md-3 col-lg-2 date-col"
                      key={date.ShowDate}
                    >
                      <div
                        className={
                          this.props.selectedDate.ShowDate === date.ShowDate
                            ? "active-date-container cursor-pointer"
                            : "date-container cursor-pointer"
                        }
                        onClick={() => {
                          this.props.handleDateClick(date);
                        }}
                      >
                        {date.ShowDate[0]}
                      </div>
                    </div>
                  );
                })
              ) : (
                <h1>No Shows Available for Selected Movie</h1>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-4">
          {this.props.selectedDate.Theaters[0].MovieTheater.map(
            (theater, index) => {
              return (
                <div key={index} className="w-100">
                  <div className="col-12 mt-3 selected-date">
                    <h5>{theater.TheaterName}</h5>
                    <p>{theater.TheaterAddress}</p>
                  </div>
                  <div className="col-12">
                    <div className="scrolling-wrapper row flex-row flex-nowrap">
                      {theater.Shows[0].MovieShow.map((show, index) => {
                        return (
                          <div
                            className="col-6 col-md-3 col-lg-2 date-col"
                            key={show.ShowId[0]}
                          >
                            <div
                              className={
                                this.props.selectedShow.ShowId === show.ShowId
                                  ? "active-date-container cursor-pointer"
                                  : "date-container cursor-pointer"
                              }
                              onClick={() => {
                                this.props.handleShowClick(show);
                              }}
                            >
                              <div className="row m-0">
                                <div className="col-12">{show.ScreenName}</div>
                                <div className="col-12">{show.ShowTime}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </>
    );
  }
}
export default MovieDate;
