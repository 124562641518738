import React, { Component } from "react";
import PinConfirm from "@/components/PinConfirm";
import ShowBank from "@/components/ShowBank";
import { applyVueInReact, connectVuex } from "vuereact-combined";
import axios from "@/axios";
import LoadingSpinner from "@/components/LoadingSpinner";
import { Modal } from "react-bootstrap";
import api from "@/helpers/api";
import { toast } from "react-toastify";

class MoviePayment extends Component {
  ShowBankComp = applyVueInReact(ShowBank);
  PinConfirmComp = applyVueInReact(PinConfirm);
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  constructor() {
    super();
    this.state = {
      banks: [],
      paymentModal: false,
      serviceId: "",
      pinVerified: false,
      loading: false,
      message: "",
      loading: true,
      pin: "",
      banks: "",
    };
  }

  componentDidMount() {
    const loadBankAndService = async () => {
      this.setState({
        banks: await api.banks(),
      });
    };
    loadBankAndService();
    this.setState({
      loading: false,
    });
  }

  makePayment = () => {
    this.setState({ paymentModal: true });
    if (this.state.pinVerified) {
      this.setState({
        loading: true,
        pinVerified: false,
        paymentModal: false,
      });
      const Seats = [];
      this.props.selectedSeats.forEach((seat) => {
        Seats.push({
          Category: seat.category,
          SeatId: seat.seat.SeatId,
          SeatName: seat.seat.SeatName,
        });
      });

      axios
        .post("api/v4/movies/payment", {
          Amount: this.props.totalAmount,
          ShowId: this.props.showDetail.ShowId[0],
          ProcessId: this.props.showDetail.ProcessId[0],
          MovieId: this.props.showDetail.MovieId[0],
          Seats,
          ShowDate: this.props.showDetail.ShowDate[0],
          ShowTime: this.props.showDetail.ShowTime[0],
          pin: this.state.pin,
        })
        .then((res) => {
          if (res.data) {
            if (res.data.message) {
              if (res.data.status === 1) {
                toast.success(res.data.message);
                this.props.vuexDispatch("UPDATE_BALANCE");
                //ticket
                const transaction = res.data.data._id;
                const ref = res.data.data.ref;

                axios
                  .get(`api/v4/transactions/export/${transaction}/${ref}`, {
                    responseType: "blob",
                  })
                  .then((response) => {
                    const ticketUrl = URL.createObjectURL(response.data);
                    window.open(ticketUrl);
                  });

                this.props.closeSeatModal();
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
          }
          this.setState({ loading: false });
        })
        .catch(() => {
          this.setState(
            {
              loading: false,
              pinVerified: false,
              paymentModal: false,
            },
            () => {
              toast.error("Something went wrong.");
            }
          );
        });
    }
  };

  pinVerified(cb, verified) {
    if (verified) {
      this.setState({ pinVerified: true, pin: verified }, () => {
        //cb(verified);
        cb();
      });
    } else {
      this.setState({
        pinVerified: false,
        paymentModal: false,
      });
    }
  }
  verifyPin = (verified) => {
    this.pinVerified(this.makePayment, verified);
  };
  previewDetails = () => {
    return {
      "Total Seats": this.props.selectedSeats.length,
      "Total Amount to Pay": "Rs. " + this.props.totalAmount,
    };
  };
  bankClicked = (bank) => {
    this.makePayment();
  };

  render() {
    return (
      <>
        <this.LoadingSpinnerComp loading={this.state.loading} />
        {this.state.paymentModal ? (
          <div className="modal-header">
            <div className="row">
              <div className="col-12">
                <this.PinConfirmComp
                  previewDetails={this.previewDetails()}
                  pinVerification={this.verifyPin}
                />
              </div>
            </div>
          </div>
        ) : (
          <Modal
            show={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="wallet-form-box">
              <div className="card-body">
                <div className="modal-header">
                  <Modal.Title>{this.props.showDetail.TheaterName}</Modal.Title>

                  <div className="row mt-4">
                    <div className="col-12 col-lg-4">
                      <p>
                        <span className="title">Movie Name:</span>
                        {this.props.showDetail.MovieName}
                      </p>
                    </div>
                    <div className="col-12 col-lg-4">
                      <p>
                        <span className="title">Screen Name:</span>
                        {this.props.showDetail.ScreenName}
                      </p>
                    </div>
                    <div className="col-12 col-lg-4">
                      <p>
                        <span className="title">Show Date:</span>
                        {this.props.showDetail.ShowDate}
                      </p>
                    </div>
                    <div className="col-12 col-lg-4">
                      <p>
                        <span className="title">Show Time:</span>
                        {this.props.showDetail.ShowTime}
                      </p>
                    </div>
                    <div className="col-12 col-lg-4">
                      <div className="d-block">
                        <span className="title">Selected Seats:</span>
                        {this.props.selectedSeats.map((selectedSeat, index) => {
                          return index > 0
                            ? "," + selectedSeat.seat.SeatName[0]
                            : selectedSeat.seat.SeatName[0];
                        })}
                      </div>
                    </div>
                    <div className="col-12 col-lg-4">
                      <p>
                        <span className="title">Total Amount:</span>
                        {this.props.totalAmount}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row mt-5 align-items-center">
                  <div className="col-sm-12 col-lg-3">
                    {this.state.banks.length > 0 && (
                      <this.ShowBankComp
                        bankClicked={this.bankClicked}
                        banks={this.state.banks}
                      />
                    )}
                  </div>
                  <div className="col-sm-12 col-lg-3">
                    <button
                      className="btn btn-text btn-block"
                      onClick={this.props.cancelConfirm}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}
export default MoviePayment;
