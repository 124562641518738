import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import axios from "@/axios";
import "./../../assets/css/movie/movieSeatLayout.css";
import ChairIcon from "@mui/icons-material/Chair";
import SquareIcon from "@mui/icons-material/Square";
import TvIcon from "@mui/icons-material/Tv";
import ShowBank from "@/components/ShowBank";
import PinConfirm from "@/components/PinConfirm";
import "@/assets/loading.scss";
import LoadingSpinner from "@/components/LoadingSpinner";
import { applyVueInReact, connectVuex } from "vuereact-combined";
import MoviePayment from "./MoviePayment";
import { toast } from "react-toastify";

class SeatLayout extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  PinConfirmComp = applyVueInReact(PinConfirm);
  ShowBankComp = applyVueInReact(ShowBank);
  constructor() {
    super();
    this.state = {
      seatRow: [],
      showDetail: {},
      selectedSeats: [],
      loading: true,
      totalAmount: 0,
      confirmPayment: false,
    };
  }

  componentDidMount() {
    // load seat layout as soon as component mounts.
    axios
      .post("api/v2/services/movies/seat", {
        MovieId: this.props.movieDetail.MovieId,
        ProcessId: this.props.movieDetail.ProcessId,
        ShowId: this.props.selectedShow.ShowId,
      })
      .then((data) => {
        this.setState({
          seatRow:
            data.data.response[0].GetMovieSeatLayoutResponse[0]
              .GetMovieSeatLayoutResult[0].SeatRows[0].MovieSeatRow,
          showDetail:
            data.data.response[0].GetMovieSeatLayoutResponse[0]
              .GetMovieSeatLayoutResult[0],
          loading: false,
        });
      });
  }

  componentDidUpdate() {}

  // Release a seat once reserved.
  releaseSeat = (seat, SeatCategory) => {
    this.setState({ loading: true });

    axios
      .post("api/v2/services/movies/seat/release", {
        ShowId: this.state.showDetail.ShowId[0],
        ProcessId: this.state.showDetail.ProcessId[0],
        SeatId: seat.SeatId[0],
        MovieId: this.state.showDetail.MovieId[0],
        SeatCategory,
      })
      .then((data) => {
        const updatedLayout =
          data.data.response[0].ReleaseMovieSeatResponse[0]
            .ReleaseMovieSeatResult[0];

        if (
          updatedLayout.Code[0] === "000" &&
          updatedLayout.SeatRows[0].MovieSeatRow.length > 0
        ) {
          const filteredSeat = this.state.selectedSeats.filter((selSeat) => {
            return selSeat.seat.SeatId[0] !== seat.SeatId[0];
          });
          this.setState((prevState) => ({
            seatRow: updatedLayout.SeatRows[0].MovieSeatRow,
            selectedSeats: filteredSeat,
            totalAmount: (
              parseFloat(prevState.totalAmount) - parseFloat(seat.Price[0])
            ).toFixed(2),
          }));
        } else {
          toast.error("Unable to release seat.");
        }
        this.setState({ loading: false });
      });
  };

  // Hold a seat.
  holdSeat = (seat, SeatCategory) => {
    this.setState({ loading: true });

    axios
      .post("api/v2/services/movies/seat/book", {
        ShowId: this.state.showDetail.ShowId[0],
        ProcessId: this.state.showDetail.ProcessId[0],
        SeatId: seat.SeatId,
        MovieId: this.state.showDetail.MovieId[0],
        SeatCategory,
      })
      .then((data) => {
        const updatedLayout =
          data.data.response[0].HoldMovieSeatResponse[0].HoldMovieSeatResult[0];
        if (
          updatedLayout.Code[0] === "000" &&
          updatedLayout.SeatRows[0].MovieSeatRow.length > 0
        ) {
          this.setState({
            seatRow: updatedLayout.SeatRows[0].MovieSeatRow,
            loading: false,
          });
          this.setState((prevState) => ({
            selectedSeats: [
              ...prevState.selectedSeats,
              { category: SeatCategory, seat },
            ],
            totalAmount: (
              parseFloat(prevState.totalAmount) + parseFloat(seat.Price[0])
            ).toFixed(2),
          }));
        } else {
          toast.error("Unable to hold seat.");
        }
      });
  };

  handleSeatClick = (seat, seatDetail) => {
    if (seat.Status[0] === "available") {
      this.holdSeat(seat, seatDetail.Category[0]);
    } else if (seat.Status[0] === "selected") {
      this.releaseSeat(seat, seatDetail.Category[0]);
    } else {
      return;
    }
  };

  //Props is used to reset both the state from parent and child.
  closeSeatModal = () => {
    this.state.selectedSeats.forEach((seat) => {
      this.releaseSeat(seat.seat, seat.category[0]);
    });
    this.setState({ selectedSeats: [] });
    this.props.closeSeatModal();
  };

  handleConfirm = () => {
    if (!this.state.selectedSeats.length > 0) {
      toast.error("Please select the seats.");
      return;
    }
    this.setState({
      confirmPayment: true,
    });
  };
  cancelConfirm = () => {
    this.setState({
      confirmPayment: false,
    });
  };
  render() {
    return (
      <>
        {this.state.confirmPayment ? (
          <>
            <MoviePayment
              showDetail={this.state.showDetail}
              selectedSeats={this.state.selectedSeats}
              cancelConfirm={this.cancelConfirm}
              totalAmount={this.state.totalAmount}
              closeSeatModal={this.closeSeatModal}
              vuexDispatch={this.props.vuexDispatch}
            />
          </>
        ) : (
          <Modal
            show={this.props.seatModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="seat-modal"
          >
            <div className="wallet-form-box">
              <div className="card-body">
                {this.state.loading && (
                  <div className="overlay">
                    <this.LoadingSpinnerComp loading={this.state.loading} />
                  </div>
                )}

                {this.state.seatRow.length > 0 ? (
                  <>
                    <div className="modal-header">
                      <Modal.Title>
                        {this.state.showDetail.TheaterName}
                      </Modal.Title>
                      <div className="title-detail row">
                        <div className="col-12 col-md-6 col-lg-4">
                          <p>
                            <span className="title">Screen Name:</span>
                            {this.state.showDetail.ScreenName}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <p>
                            <span className="title">Show Date:</span>
                            {this.state.showDetail.ShowDate}
                          </p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                          <p>
                            <span className="title">Show Time:</span>
                            {this.state.showDetail.ShowTime}
                          </p>
                        </div>
                      </div>
                    </div>

                    <Modal.Body>
                      <div className="seat-layout">
                        {this.state.seatRow.map((row, index) => {
                          return (
                            <div
                              className="seat-row d-flex justify-content-center align-items-center"
                              key={`${row.RowName}`}
                            >
                              <div className="rowName mr-2">
                                <p>{row.RowName}</p>
                              </div>
                              <div className="seats-container d-flex">
                                {row.Seats[0].MovieSeat.map((seat, index) => {
                                  return (
                                    <div
                                      className="seat-icon-container flex-fill"
                                      key={`${row.RowName}-${seat.SeatId}-${index}`}
                                      onClick={() => {
                                        this.handleSeatClick(seat, row);
                                      }}
                                    >
                                      {seat.SeatId[0] !== "" && (
                                        <ChairIcon
                                          className={
                                            seat.Status[0] === "selected"
                                              ? "seat-selected seat-icon"
                                              : seat.Status[0] === "available"
                                              ? "seat-available seat-icon"
                                              : seat.Status[0] === "unavailable"
                                              ? "seat-unavailable seat-icon"
                                              : seat.Status[0] === "reserved"
                                              ? "seat-reserved seat-icon"
                                              : "seat-sold seat-icon"
                                          }
                                        />
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      {/* SCREEN SECTION */}
                      <div className="tv-screen mt-4 d-flex justify-content-center align-items-center">
                        <TvIcon className="tv-icon" />
                      </div>

                      {/* COLOR LABEL */}
                      <div className="row mt-4 justify-content-lg-center">
                        {[
                          "unavailable",
                          "sold",
                          "available",
                          "reserved",
                          "selected",
                        ].map((status, index) => {
                          return (
                            <div
                              className="col-12 col-md-6 col-lg-2"
                              key={status}
                            >
                              <div className="status-block">
                                <div className={`status-color seat-${status}`}>
                                  <SquareIcon />
                                </div>
                                <div className="status-name">{status}</div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {/* SELECTED SEATS AND TOTAL AMOUNT SECTION */}

                      <div className="row mt-5">
                        <div className="col-12">
                          <p>
                            <span className="title">Selected Seats:</span>
                            {this.state.selectedSeats.map(
                              (selectedSeat, index) => {
                                return index > 0
                                  ? "," + selectedSeat.seat.SeatName[0]
                                  : selectedSeat.seat.SeatName[0];
                              }
                            )}
                          </p>
                          <p>
                            <span className="title">Total Amount:</span>
                            {this.state.totalAmount}
                          </p>
                        </div>
                      </div>
                      {/* CONFIRM AND CANCEL SECTION */}
                      <div className="row mt-4 p-0">
                        <div className="col-12">
                          <button
                            className="btn btn-primary btn-text"
                            onClick={() => {
                              this.handleConfirm();
                            }}
                          >
                            Confirm
                          </button>
                          <button
                            className="ml-2 btn btn-text"
                            onClick={this.closeSeatModal}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Modal.Body>
                  </>
                ) : (
                  <Modal.Body>
                    <div className="card-loader"></div>
                  </Modal.Body>
                )}
              </div>
            </div>
          </Modal>
        )}
      </>
    );
  }
}
export default SeatLayout;
